#up-button {
    position: fixed;
    bottom: 20pt;
    right: 10%;

    border: solid black;
    border-width: 0 15px 15px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    opacity: 0.3;

    cursor: pointer;
}

#up-button:hover {
    opacity: 1;
    border-color: lightgreen;
}
