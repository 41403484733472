body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.jumbotron {
  position: relative;
  padding-left: 0pt;
  padding-right: 0pt;
  width: 100%;
  background-color: #eee;
  padding-top: 15pt;
}

@media (max-width: 768px) {
  .jumbotron {
    padding-left: 0pt;
    padding-right: 0pt;
    padding-bottom: 0pt;
    margin-bottom: 0pt;
  }
}

@media (min-width: 1024px) {
  #info-block {
    position: fixed;
  }
}

@media (min-width: 768px) {
  .jumbotron {
    box-shadow: 0 0 10pt gray;
    margin-top: 40pt;
    position: relative;
    padding-bottom: 20pt;
    padding-left: 15pt;
    padding-right: 15pt;
  }
}

.photo {
  width: 100%;
  padding-bottom: 5pt;
  max-width: 250px;
}

.left-block {
  text-align: center;
}

.grayed {
  color: gray;
}

.delimiter {
  font-weight: bolder;
  margin-left: 5pt;
  margin-right: 5pt;
}

p > span {
  margin-bottom: 2pt;
  display: inline-block;
}

h1,
h2 {
  font-size: 16pt;
}
h2 {
  padding-left: 5pt;
  border-bottom: solid black 1pt;
}
h3,
h4 {
  font-size: 14pt;
  padding-top: 10pt;
}

.block > p {
  padding-left: 20pt;
}

.footer {
  color: grey;
  padding-left: 5pt;
  margin-top: 10pt;
  padding-bottom: 10pt;
}

.block {
  padding-bottom: 20pt;
}

iframe {
  width: 80%;
}
