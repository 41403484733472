.img {
    width: 100%;
    text-align: center;
    margin-top: 40pt;
    margin-bottom: 40pt;
}

img.screenshot {
    width: 80%;
}
